<template>
    <div class="container new-article">
        <div>
            <router-link class="go-back" :to="{ name: 'news' }">Повернутися до списку</router-link>
        </div>
        <div>
            <h1>
                {{ article.title }}
            </h1>
        </div>
        <div>
            <img :src="article.previewImageUrl">
        </div>

        <div>
            <div v-html="article.content"></div>
        </div>
        <div  class="author" v-if="article.author">
            {{ article.author }}
        </div>
        <div v-if="article.publishedAt" class="date">
            {{ article.publishedAt }}
        </div>
    </div>
</template>

<script>

// import preview from './components/preview';
import { show as articleShow } from '@/api/article';
// import previewLoader from './components/previewLoader';

export default {
    components: {
        // previewLoader,
        // preview
    },
    data: function () {
        return {
            condition: {
                isLoadArticle: true,
            },
            article: {},
        }
    },
    created: async function() {
        //TODO сделать через Vuejs
        $('.router-section').append('<div class="router-preload"><div class="spinner"></div></div>');
        setTimeout(function(){
            $('.router-section .router-preload').remove();
        }, 600)
        let response =  await articleShow(this.$route.params.slug).catch(() => {
            this.$router.push('/prime')
        });

        if (response?.data?.data) {
            this.article = response.data.data
        } else {
            this.$router.push('/prime')
        }
    },
    mounted () {
      window.scrollTo(0, 0)
    },
}
</script>

<style lang="scss" scoped>
    .author {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: #24282c;
    }
    .banner-preview {
        border-radius: 5px;
        width: 100%;
        height: 300px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: right bottom;
        object-position: right bottom;
    }
    .date {
        font-size: 18px;
    }
</style>
